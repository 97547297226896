import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_USER,
  REGISTER_USER,
  VERIFY_OTP,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PROFILE_ADD_DATA,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  verifyOTPError,
//  addProfileDataSuccess,
  addProfileDataError,
} from './actions';

import { adminRoot, UserRole } from '../../constants/defaultValues';
import { setCurrentUser, setProfileData } from '../../helpers/Utils';
import instance from '../../helpers/axiosInstance';
import AppError from '../../helpers/AppError';

export function* watchAddProfile() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(PROFILE_ADD_DATA, addProfileData);
}

const addProfileDataAsync = async (fullName, userID,email2, contactNo,about) => {
  const response = await instance({
    method: 'POST',
    url: '/addProfile',
    data: {
      full_name: fullName,
      user_id: userID,
      email: email2,
      contact_no: contactNo,
      bio:about,
    },
  });
  return { ...response.data };
};


const getProfileDataAsync = async (id) => {
  const response = await instance({
    method: 'GET',
    url: `/profile/${id}`,
  });

  return { ...response.data };
};



function* addProfileData({ payload }) {
  const { fullName,email, userID, contactNo,about } = payload.profile;
  const { history } = payload;
  try {
    const profileData = yield call(
      addProfileDataAsync,
      fullName,
      userID,
      email,
      contactNo,
      about
    );
    if (profileData.success) {
    //  const { details } = profileData;

    const data = yield call(getProfileDataAsync, userID);
    if (data.success) {
      const { profile } = data;
      console.log(profile);
      setProfileData({ ...profile });
      console.log("yaha hu");
    } else {
      setProfileData();
    }
    console.log("asdfghjkl");
    //  yield put(addProfileDataSuccess(details));
      history.push(`${adminRoot}/profile/about`);
    } else {
      yield put(addProfileDataError(profileData.message));
    }
  }
    catch (error) {
    yield put(addProfileDataError(error));
  }
}

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const response = await instance({
    method: 'POST',
    url: '/auth/login',
    data: { email, password },
  });

  const { data } = response;
  return data;
};


function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

    if (loginUser.success) {
      const { user } = loginUser.data;
      if (user.role !== UserRole.User) {
        throw new AppError(
          'Please login through User Account',
          'Authentication Error'
        );
      }
      setCurrentUser(user);
      const { id } = user;
      yield put(loginUserSuccess(user));
      getProfileDataAsync(id);
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.error));
    }
  } catch (error) {
    yield put(loginUserError(error.message));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (
  email,
  password,
  phone,
  userType = 0
) => {
  const response = await instance({
    method: 'POST',
    url: '/auth/register',
    data: { email, password, phone_number: phone, user_type: userType },
  });

  const { data } = response;

  return data;
};

function* registerWithEmailPassword({ payload }) {
  const { email, password, phone } = payload.user;
  const { history } = payload;

  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      phone
    );

    if (registerUser.success) {
      window.localStorage.setItem('_token', registerUser.token);
      window.localStorage.setItem('_password', password);
      window.localStorage.setItem('_email', email);
      yield put(registerUserSuccess(registerUser.message));
      history.push('/user/verify-otp');
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  setCurrentUser(null);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  const response = await instance({
    method: 'POST',
    url: '/auth/forgotPassword',
    data: { email },
  });

  const { data } = response;
  return data;
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  const {history} = payload;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    console.log(forgotPasswordStatus);
    if (forgotPasswordStatus.success) {
      window.localStorage.setItem('_token', forgotPasswordStatus.token);
      console.log(forgotPasswordStatus.token);
      yield put(forgotPasswordSuccess('success'));
      history.push('/user/reset-password');
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (newPassword,OTP) => {
  const response = await instance({
    method: 'POST',
    url: '/auth/change-password',
    data: {
      password: newPassword,
      otp: OTP,
    },
  });

  const { data } = response;
  return data;
};

function* resetPassword({ payload }) {
  const { newPassword, OTP } = payload;
  const { history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      newPassword,
      OTP
    );
    if (resetPasswordStatus.success) {
      yield put(resetPasswordSuccess(resetPasswordStatus.message));
      console.log("heyy");
      history.push('/user/login');
    } else {
      yield put(resetPasswordError("Reset Password Error"));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}


export function* watchVerifyOTP() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(VERIFY_OTP, verifyOTP);
}

const verifyOTPAsync = async (OTP) => {
  const load = {
    otp: OTP,
    verify_token: window.localStorage.getItem('_token'),
    password: window.localStorage.getItem('_password'),
  };

  const response = await instance({
    method: 'POST',
    url: '/auth/verifyOtp',
    data: load,
  });

  const { data } = response;
  return data;
};

function* verifyOTP({ payload }) {
  const { OTP } = payload;
  const { history } = payload;

  try {
    const verifyOTPStatus = yield call(verifyOTPAsync, OTP);

    if (verifyOTPStatus.success) {
      const userData = verifyOTPStatus.data.user;
      window.localStorage.removeItem('_token');
      window.localStorage.removeItem('_password');
      setCurrentUser(userData);
      yield put(loginUserSuccess(userData));
      history.push('/user/complete-registration');
    } else {
      yield put(verifyOTPError(verifyOTPStatus.message));
    }
  } catch (error) {
    yield put(verifyOTPError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchVerifyOTP),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchAddProfile),
  ]);
}
