// eslint-disable-next-line import/no-cycle
import {
  GET_ALL_USER_HACKATHONS,
  GET_ALL_USER_HACKATHONS_SUCCESS,
  GET_ALL_USER_HACKATHONS_ERROR,
  GET_CURRENT_HACKATHON,
  GET_CURRENT_HACKATHON_SUCCESS,
  GET_CURRENT_HACKATHON_ERROR,
} from '../actions';

export const getAllHackathons = (accessToken, id) => ({
  type: GET_ALL_USER_HACKATHONS,
  payload: { accessToken, id },
});
export const getAllHackathonsSuccess = (data) => ({
  type: GET_ALL_USER_HACKATHONS_SUCCESS,
  payload: { data },
});
export const getAllHackathonsError = (message) => ({
  type: GET_ALL_USER_HACKATHONS_ERROR,
  payload: { message },
});

export const getCurrentHackathon = (accessToken, id) => ({
  type: GET_CURRENT_HACKATHON,
  payload: { accessToken, id },
});
export const getCurrentHackathonSuccess = (data) => ({
  type: GET_CURRENT_HACKATHON_SUCCESS,
  payload: { data },
});
export const getCurrentHackathonError = (message) => ({
  type: GET_CURRENT_HACKATHON_ERROR,
  payload: { message },
});
