export const UserRole = {
  User: 0,
  SuperAdmin: 1,
  Organization: 2,
  Admin: 3,
  Mentor: 4,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'de', name: 'German', direction: 'ltr' },
];

export const adminRoot = '/home';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  role: UserRole.User,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.bluenavy';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const currentUserStorageKey = 'current_user';
export const profileDataStorageKey = 'profile_data';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const apiUri = 'https://api.smart-natives.de/index.php/api';

export const genderOptions = [
  { label: 'Male', value: 'Male', key: 0 },
  { label: 'Female', value: 'Female', key: 1 },
  { label: 'Other', value: 'Other', key: 2 },
];
export const shirtSizeOptions = [
  { label: 'XXS', value: 'XXS', key: 0 },
  { label: 'XS', value: 'XS', key: 1 },
  { label: 'S', value: 'S', key: 2 },
  { label: 'M', value: 'M', key: 3 },
  { label: 'L', value: 'L', key: 4 },
  { label: 'XL', value: 'XL', key: 5 },
  { label: 'XXL', value: 'XXL ', key: 6 },
];
export const hackthonApplication = {
  about: [
    {
      id: 'first-and-last-name',
      messageId: 'challenges.about.first-and-last-name',
      databaseKey: 'full_name',
      value: 1,
      key: 0,
    },
    {
      id: 'bio',
      messageId: 'challenges.about.bio',
      databaseKey: 'bio',
      value: 2,
      key: 1,
    },
    {
      id: 'gender',
      messageId: 'challenges.about.gender',
      databaseKey: 'gender',
      value: 3,
      key: 2,
    },
    {
      id: 'dob',
      messageId: 'challenges.about.dob',
      databaseKey: 'dob',
      value: 4,
      key: 3,
    },
    {
      id: 'tsirt-size',
      messageId: 'challenges.about.tshirt-size',
      databaseKey: 'tshirt_size',
      value: 5,
      key: 4,
    },
  ],
  contact: [
    {
      id: 'phone-number',
      messageId: 'challenges.contact.phone-number',
      databaseKey: 'contact_no',
      value: 1,
      key: 0,
    },
    {
      id: 'email',
      messageId: 'challenges.contact.email',
      databaseKey: 'email',
      value: 2,
      key: 1,
    },
    {
      id: 'city',
      messageId: 'challenges.contact.city',
      databaseKey: 'city',
      value: 3,
      key: 2,
    },
    {
      id: 'emergency-contact',
      messageId: 'challenges.contact.emergency-contact',
      databaseKey: null,
      value: 4,
      key: 3,
    },
  ],
  education: [
    {
      id: 'education-details',
      messageId: 'challenges.education.education-details',
      databaseKey: null,
      value: 1,
      key: 0,
    },
  ],
  experience: [
    {
      id: 'domain-expertise',
      messageId: 'challenges.experience.domain-expertise',
      databaseKey: 'experiences',
      value: 1,
      key: 0,
    },
    {
      id: 'skills',
      messageId: 'challenges.experience.skills',
      databaseKey: 'experiences',
      value: 2,
      key: 1,
    },
    {
      id: 'resume',
      messageId: 'challenges.experience.resume',
      databaseKey: 'experiences',
      value: 3,
      key: 2,
    },
    {
      id: 'work-experience',
      messageId: 'challenges.experience.work-experience',
      databaseKey: 'experiences',
      value: 4,
      key: 3,
    },
  ],
  online_profiles: [
    {
      id: 'github',
      messageId: 'challenges.online-profiles.github',
      databaseKey: 'github',
      value: 1,
      key: 0,
    },
    {
      id: 'dribble',
      messageId: 'challenges.online-profiles.dribble',
      databaseKey: 'dribble',
      value: 1,
      key: 0,
    },
    {
      id: 'behance',
      messageId: 'challenges.online-profiles.behance',
      databaseKey: 'behance',
      value: 1,
      key: 0,
    },
    {
      id: 'stack-overflow"',
      messageId: 'challenges.online-profiles.stack-overflow',
      databaseKey: 'stack_overflow',
      value: 1,
      key: 0,
    },
    {
      id: 'linkedin',
      messageId: 'challenges.online-profiles.linkedin',
      databaseKey: 'linkedin',
      value: 1,
      key: 0,
    },
    {
      id: 'facebook',
      messageId: 'challenges.online-profiles.facebook',
      databaseKey: 'facebook',
      value: 1,
      key: 0,
    },
    {
      id: 'twitter',
      messageId: 'challenges.online-profiles.twitter',
      databaseKey: 'twitter',
      value: 1,
      key: 0,
    },
  ],
};
