import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import instance from '../../helpers/axiosInstance';
import { log } from '../../helpers/Utils';
import {
  getAllHackathonsError,
  getAllHackathonsSuccess,
  GET_ALL_USER_HACKATHONS,
  GET_CURRENT_HACKATHON,
} from '../actions';
import {
  getCurrentHackathonError,
  getCurrentHackathonSuccess,
} from './actions';

const getHackathonsAsync = async (accessToken, id) => {
  const response = await instance({
    url: `/userhackathonlist/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

const getCurrentHackathonAsync = async (accessToken, id) => {
  const response = await instance({
    url: `/getdetails/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

function* getOrganizerHackathons({ payload }) {
  const { accessToken, id } = payload;

  try {
    const hackathons = yield call(getHackathonsAsync, accessToken, id);

    if (hackathons.success) {
      yield put(getAllHackathonsSuccess(hackathons.data));
    } else {
      yield put(getAllHackathonsError('Error getting Hackathons.'));
    }
  } catch (err) {
    log(err);
    yield put(getAllHackathonsError('Error getting Hackathons.'));
  }
}

function* getCurrentHackathon({ payload }) {
  const { accessToken, id } = payload;

  try {
    const hackathons = yield call(getCurrentHackathonAsync, accessToken, id);
    log(hackathons);
    if (hackathons.success) {
      yield put(getCurrentHackathonSuccess(hackathons.getdetails));
    } else {
      yield put(getCurrentHackathonError('Error getting Hackathon details.'));
    }
  } catch (err) {
    log(err);
    yield put(getCurrentHackathonError('Error getting Hackathon details.'));
  }
}

export function* watchGetOrganizerHackathons() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_ALL_USER_HACKATHONS, getOrganizerHackathons);
}

export function* watchGetCurrentHacathon() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_CURRENT_HACKATHON, getCurrentHackathon);
}

export default function* rootSaga() {
  yield all([fork(watchGetOrganizerHackathons)]);
}
