import {
  GET_ALL_USER_HACKATHONS,
  GET_ALL_USER_HACKATHONS_SUCCESS,
  GET_ALL_USER_HACKATHONS_ERROR,
  GET_CURRENT_HACKATHON,
  GET_CURRENT_HACKATHON_SUCCESS,
  GET_CURRENT_HACKATHON_ERROR,
} from '../actions';

const INIT_STATE = {
  organizerHackathons: [],
  currentHackathon: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USER_HACKATHONS:
      return { ...state, loading: true };
    case GET_ALL_USER_HACKATHONS_SUCCESS:
      return {
        ...state,
        organizerHackathons: action.payload.data,
        loading: false,
      };
    case GET_ALL_USER_HACKATHONS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };

    case GET_CURRENT_HACKATHON:
      return { ...state, loading: true };
    case GET_CURRENT_HACKATHON_SUCCESS:
      return {
        ...state,
        currentHackathon: action.payload.data,
        loading: false,
      };
    case GET_CURRENT_HACKATHON_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    default:
      return { ...state };
  }
};
